/* eslint-disable prettier/prettier */
import React from 'react'

const Loader = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
}

export default Loader
