/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const ProtectRoute = ({ children }) => {
  const { pathname } = useLocation()
  const adminToken = sessionStorage.getItem('token')

  if (!adminToken && pathname !== '/') {
    return <Navigate to="/" />
  }

  if (!adminToken) {
    return <Navigate to="/" />
  }

  return children
}

export default ProtectRoute
