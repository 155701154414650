import React, { Component, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Loader from './utils/Loader'
import ProtectedRoutes from './components/ProtectRoute'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" name="Login Page" element={<Login />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={
              <ProtectedRoutes>
                <DefaultLayout />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
    )
  }
}

export default App
